import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Cyber/TeasureHunt/image1.jpeg';
// import p2 from 'assests/Photos/Clubs/Cyber/TeasureHunt/image2.jpeg';
// import p3 from 'assests/Photos/Clubs/Cyber/TeasureHunt/image3.jpeg';
// import p4 from 'assests/Photos/Clubs/Cyber/TeasureHunt/image4.jpeg';
import CyberClub from 'views/CyberClub';


import { Typography } from '@mui/material';
import Container from 'components/Container';


const TreasureHunt = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/TeasureHunt/image1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/TeasureHunt/image2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/TeasureHunt/image3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/TeasureHunt/image4.webp`;
 
  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘TREASURE HUNT’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Class: 9 to 12  ,  Date: 29 October 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        An inter house event “Treasure Hunt” was conducted by the Cyber Association on 29 October,
2022 to test the students’ ability to decipher and decrypt clues. The first round was a ‘Crossword
Puzzle’ related to computer science terminologies. The final round comprised of the ‘Treasure
Hunt’ where the teams competed to decipher the hidden message using clues. It took them on a
journey that culminated in finding the treasure.<br/><br/>
Students participated enthusiastically and exhibited their analytical, logical and reasoning skills to
reach the final treasure. Quick thinking and team work played a crucial role in deciding the
winners.
        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “NOT ALL TREASURE IS SILVER AND GOLD”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <CyberClub />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default TreasureHunt;